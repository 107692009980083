import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamModule, WebcamUtil } from 'ngx-webcam';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
    imports: [CommonModule, ClarityModule, WebcamModule, MrTranslatePipe],
    standalone: true
})
export class CameraComponent implements OnInit, AfterViewInit {
  @Output() onTakenFoto: EventEmitter<string> = new EventEmitter<string>();

  @Output() showWebcamChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set showWebcam(isOpen: boolean) {
    this._showWebcam = isOpen;
    if (!isOpen) this.showWebcamChange.emit(false);
  }
  get showWebcam(): boolean {
    return this._showWebcam;
  }
  private _showWebcam: boolean;

  protected webcamReady: boolean = false;
  private deviceId: string;

  protected multipleWebcamsAvailable = false;
  protected allowCameraSwitch = true;
  protected videoOptions: MediaTrackConstraints = {
    aspectRatio: { ideal: 16 / 9 }
  };

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  protected get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  protected get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  protected resizeReady: boolean = true;
  @ViewChild('sizeRef') sizeRef: ElementRef<HTMLDivElement>;
  sizePx: {width?: number, height?: number} = {};




  constructor(
    private toastr: ToastrService
  ) {  }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.resizeCamera();
    });
    screen.orientation.onchange = (_) => {
      this.resizeReady = false;
      this.resizeCamera();
      setTimeout(() => {
        this.resizeReady = true;
      });
    }
  }

  private resizeCamera() {
    if (this.sizeRef && this.showWebcam) {
      const width = this.sizeRef.nativeElement.offsetWidth - 2;
      const height = this.sizeRef.nativeElement.parentElement.offsetHeight - 10;
      let ratio = 16 / 9; // Horizonal
      if (width < height) ratio = 9 / 16; // Vertical
      this.videoOptions = { aspectRatio: { ideal: ratio } };
      this.sizePx = {
        height: height,
        width: height * ratio
      };
    }
  }


  protected takePicture(){
    this.trigger.next();
  }

  protected handleImage(image: WebcamImage) {
    this.onTakenFoto.emit(image.imageAsDataUrl);
  }

  protected handleInitError(error: WebcamInitError): void {
    this.webcamReady = true;
    this.toastr.error(error.message);
  }

  protected showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }
}
